import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const defaultLng = 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: defaultLng,
    interpolation: {
      escapeValue: false,
    },
    // Namespaces
    ns: [
      'activities',
      'chat',
      'common',
      'community',
      'dataVisualization',
      'download',
      'home',
      'learning',
      'login',
      'meals',
      'profile',
      'surveys',
      'validations',
      'temporary',
    ],
    saveMissing: true,
    missingKeyHandler: (_lngs, _ns, key, fallbackValue) => {
      console.warn(`Missing key: ${key}, fallback: ${fallbackValue}`);
    },
  });

// d
i18n?.services?.formatter?.add('DAY_SHORT', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mmmm
i18n?.services?.formatter?.add('MONTH_LONG', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mm/dd/yyyy
i18n?.services?.formatter?.add('MONTH_DAY_YEAR_SHORT', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mm/dd/yyyy UTC
i18n?.services?.formatter?.add('MONTH_DAY_YEAR_UTC_SHORT', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mm/dd UTC
i18n?.services?.formatter?.add('MONTH_DAY_UTC_SHORT', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// dddd, mmmm dd UTC
i18n?.services?.formatter?.add('WEEKDAY_MONTH_DAY_UTC_LONG', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: '2-digit',
    timeZone: 'UTC',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mmmm dd UTC
i18n?.services?.formatter?.add('MONTH_DAY_UTC', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mmmm dd, yyyy
i18n?.services?.formatter?.add('MONTH_DAY_YEAR_LONG', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mmmm dd, yyyy UTC
i18n?.services?.formatter?.add('MONTH_DAY_YEAR_UTC_LONG', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

// mmmm yyyy
i18n?.services?.formatter?.add('MONTH_YEAR_LONG', (value, lng) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    year: 'numeric',
  };

  return Intl.DateTimeFormat(lng, options).format(new Date(value));
});

export default i18n;
