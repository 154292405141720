import React from 'react';
import { DefaultBasePage, SurveysEmptyState } from '@components';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, Stack, useTheme } from '@mui/material';
import {
  SurveyFrequency,
  SurveyInstance,
  SurveyStatus,
  useGetAvailableSurveysQuery,
  useGetProfileQuery,
  UserType,
} from '@fdha/graphql-api-patient';
import { List, Typography } from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import { useAnalytics } from '@fdha/common-hooks';

import SurveyCard from './SurveyCard';

const Surveys = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { analyticsClient } = useAnalytics();

  const welcomeSurveyName = 'Welcome';
  const i18nWelcomeNameKey = 'surveys:welcome';

  const { data, networkStatus } = useGetAvailableSurveysQuery({
    variables: { showDataSurveys: true, version: 5 },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: profileData } = useGetProfileQuery();
  const onboardingFinished: boolean =
    !!profileData?.me.cancer_type?.trim() ||
    !!profileData?.me.diag_details?.trim() ||
    !!profileData?.me.diag_date?.trim() ||
    !!profileData?.me.diag_date_desc?.trim();

  const showWelcomeSurvey =
    profileData?.me.type !== UserType.ClinicalTrialPatient &&
    !onboardingFinished;

  const surveys = data?.availableSurveys.edges.map((edge) => edge.node) || [];

  const isLoading = networkStatus === NetworkStatus.loading;

  // TODO: Fetch unscheduled surveys here when we have the query for it
  const unscheduledSurveys = [];

  const renderLoadingState = () => (
    <Stack spacing={2}>
      {[...Array(7)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={96} />
      ))}
    </Stack>
  );

  const navigateToSurvey = (item: SurveyInstance) => {
    navigate(item.id);
    analyticsClient?.track('Survey Opened', {
      id: item.id,
      name: item.name,
      type: item.instanceType,
    });
  };

  const navigateWelcome = () => {
    navigate('welcome');
    analyticsClient?.track('Survey Opened', {
      welcomeSurveyName,
      type: 'welcome',
    });
  };

  const renderWelcomeSurvey = () => (
    <Box mb={2}>
      <SurveyCard
        key="welcome"
        name={welcomeSurveyName}
        i18nNameKey={i18nWelcomeNameKey}
        status={SurveyStatus.Open}
        frequency={SurveyFrequency.Once}
        handleClick={navigateWelcome}
      />
    </Box>
  );

  const renderItem = (item: SurveyInstance) => (
    <SurveyCard
      key={item.id}
      name={item.name}
      status={item.status}
      frequency={item.schedule?.frequency}
      dueAt={item.due_at}
      weekDays={item.schedule?.weekDays || undefined}
      handleClick={() => navigateToSurvey(item)}
    />
  );

  const renderEmptyState = () => {
    if (showWelcomeSurvey) return;

    return <SurveysEmptyState />;
  };

  const renderSurveyTypeTitle = (type: 'available' | 'required') => {
    const fallback = type === 'available' ? 'Available' : 'Required';

    return (
      <Typography
        i18nKey={`surveys:screen.${type}`}
        variant="body2"
        color={theme.palette.text.secondary}
        mb={2}
      >
        {fallback}
      </Typography>
    );
  };

  return (
    <DefaultBasePage
      i18nKeyTitle="surveys:title"
      title="Surveys"
      contentSize="small"
    >
      <Typography
        i18nKey="surveys:subtitle"
        color={theme.palette.text.secondary}
        mb={2}
      >
        This is how your Coach can stay in the loop, all information is
        confidential between the two of you.
      </Typography>
      {isLoading ? (
        renderLoadingState()
      ) : (
        <>
          {surveys.length > 0 && renderSurveyTypeTitle('required')}
          {showWelcomeSurvey && renderWelcomeSurvey()}
          <List
            items={surveys}
            renderItem={renderItem}
            renderEmptyState={renderEmptyState}
            isLoading={isLoading}
          />
          {unscheduledSurveys.length > 0 && (
            <>
              {renderSurveyTypeTitle('available')}
              {/* TODO: Switch to unscheduledSurveys information below */}
              <List
                items={surveys}
                renderItem={renderItem}
                renderEmptyState={renderEmptyState}
                isLoading={isLoading}
              />
            </>
          )}
        </>
      )}
    </DefaultBasePage>
  );
};

export default Surveys;
