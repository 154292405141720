import React, { useEffect } from 'react';
import { useSystemSetting } from '@fdha/common-hooks';
import {
  ChangePassword,
  Login,
  ForgotPassword,
  ResetPassword,
  OtpFlow,
  Download,
} from '@pages';
import { useLocalStorage } from '@hooks';
import { SplashLoader } from '@components';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

export const publicPageNameByRoute = [
  { route: '/', name: 'Login', i18nKey: 'login:pageTitle' },
  {
    route: '/change-password',
    name: 'Change Password',
    i18nKey: 'login:changePassword.title',
  },
  {
    route: '/forgot-password',
    name: 'Forgot Password',
    i18nKey: 'login:forgotPassword.pageTitle',
  },
  {
    route: '/reset-password',
    name: 'Reset Password',
    i18nKey: 'login:forgotPassword.title',
  },
  { route: '/download', name: 'Download' },
];

export const Public = () => {
  const { value: isOtpEnabled, isLoading: isOtpEnabledLoading } =
    useSystemSetting<boolean>('isOtpEnabled', { allowAutomaticRefetch: false });

  const { value: termsOfServiceLink, isLoading: loadingSystemSettingTerms } =
    useSystemSetting('termsOfServiceLink', { allowAutomaticRefetch: false });

  const { value: privacyPolicyLink, isLoading: loadingSystemSettingPrivacy } =
    useSystemSetting('privacyPolicyLink', { allowAutomaticRefetch: false });

  const location = useLocation();
  const { saveToLocalStorage } = useLocalStorage('redirectUrl', false);

  useEffect(() => {
    const currentUrl = location.pathname + location.search;
    if (!publicPageNameByRoute.some((page) => page.route === currentUrl)) {
      saveToLocalStorage({ url: currentUrl });
    }
  }, [location, saveToLocalStorage]);

  if (
    isOtpEnabledLoading ||
    loadingSystemSettingTerms ||
    loadingSystemSettingPrivacy
  ) {
    return <SplashLoader />;
  }

  return (
    <Routes>
      <Route element={<ScrollToTop />}>
        {isOtpEnabled ? (
          <>
            <Route path="/" element={<OtpFlow />} />
          </>
        ) : (
          <>
            <Route
              path="/change-password"
              element={
                <ChangePassword
                  privacyPolicyLink={privacyPolicyLink}
                  termsOfServiceLink={termsOfServiceLink}
                />
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/" element={<Login />} />
          </>
        )}
        <Route path="/download" element={<Download />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};
