import React, { useEffect } from 'react';
import {
  useLocalStorage,
  useNotifications,
  useReadLocalStorage,
  useRemoveTrailingSlash,
} from '@hooks';
import {
  NotificationTypeV2,
  PlatformType,
  useGetCommunityUserQuery,
  useGetProfileQuery,
  useGetUserIntercomHashKeyQuery,
} from '@fdha/graphql-api-patient';
import {
  SignInEvent,
  useActivityDetector,
  useAnalytics,
  useChat,
  useFeatureFlag,
  useSystemSetting,
} from '@fdha/common-hooks';
import { NetworkStatus } from '@apollo/client';
import { AppBar, DefaultBasePage, SplashLoader } from '@components';
import { Box } from '@mui/material';
import {
  Home,
  Profile,
  YourAccount,
  Notifications,
  MedicalInformation,
  Surveys,
  SurveySteps,
  Chat,
  Documents,
  CoachesUploads,
  YourUploads,
  Learning,
  ProfileChangePassword,
  CoursesWrapper,
  Community,
  CommunityUnavailable,
  Articles,
  CourseHome,
  Meals,
  CourseItem,
  Help,
  FaqCategory,
  MealsHelp,
  ViewPost,
  ResetPassword,
  AddOrEditPost,
  CommunityNotifications,
  CommunityInitial,
  TrialData,
  Recipes,
  Recipe,
  BHBTrialData,
  BHBRecommendations,
  Delivery,
  Download,
} from '@pages';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { omit } from 'lodash';
import { useDialog } from '@fdha/web-ui-library';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';

import i18n from '../i18n';

import ScrollToTop from './ScrollToTop';

export const privatePageNameByRoute = [
  { route: '/', name: 'Home', i18nKey: 'home:pageTitle' },
  { route: '/profile', name: 'Profile', i18nKey: 'profile:title' },
  { route: '/courses', name: 'Courses', i18nKey: 'learning:course.title' },
  {
    route: '/courses/[:courseId]',
    name: 'Course',
    i18nKey: 'learning:pageTitle',
  },
  {
    route: '/courses/[:courseId]/[:itemId]',
    name: 'Course Module',
    i18nKey: 'learning:course.module',
  },
  { route: '/learning', name: 'Learning', i18nKey: 'learning:title' },
  {
    route: '/learning/courses',
    name: 'Courses',
    i18nKey: 'learning:course.title',
  },
  {
    route: '/learning/courses/[:courseId]',
    name: 'Course',
    i18nKey: 'learning:pageTitle',
  },
  {
    route: '/learning/courses/[:courseId]/[:itemId]',
    name: 'Course Module',
    i18nKey: 'learning:course.module',
  },
  {
    route: '/learning/articles',
    name: 'Explore',
    i18nKey: 'learning:article.title',
  },
  {
    route: '/learning/recipes',
    name: 'Recipes',
    i18nKey: 'learning:recipe.title',
  },
  {
    route: '/profile/your-account',
    name: 'Your Account',
    i18nKey: 'profile:yourAccount.title',
  },
  {
    route: '/profile/medical-information',
    name: 'Medical Information',
    i18nKey: 'profile:medicalInformation.title',
  },
  {
    route: '/profile/notifications',
    name: 'Notifications',
    i18nKey: 'profile:notifications.title',
  },
  {
    route: '/profile/change-password',
    name: 'Change Password',
    i18nKey: 'profile:changePassword.title',
  },
  {
    route: '/profile/documents',
    name: 'Documents',
    i18nKey: 'profile:documents.title',
  },
  {
    route: '/profile/help',
    name: 'Help',
    i18nKey: 'profile:help.title',
  },
  {
    route: '/profile/documents/coaches-uploads',
    name: 'Coaches Uploads',
    i18nKey: 'profile:documents.folders.coachesUploads.title',
  },
  {
    route: '/profile/documents/your-uploads',
    name: 'Your Uploads',
    i18nKey: 'profile:documents.folders.yourUploads.title',
  },
  {
    route: '/surveys',
    name: 'Surveys',
    i18nKey: 'surveys:title',
  },
  { route: '/community', name: 'Community' },
  { route: '/community/notifications', name: 'Notifications' },
  { route: '/community/[:id]', name: 'Post' },
  { route: '/community/write-post', name: 'Write a post' },
  { route: '/community/[:id]', name: 'Post' },
  { route: '/community/[:id]/edit-post', name: 'Edit Post' },
  { route: '/chat', name: 'Chat', i18nKey: 'chat:title' },
  { route: '/meals', name: 'Meals', i18nKey: 'meals:title' },
  { route: '/meals/help', name: 'Help', i18nKey: 'meals:help.title' },
  {
    route: '/meals/[:id]',
    name: 'Meal Details',
    i18nKey: 'meals:deliveryPreview.pageTitle',
  },
  {
    route: '/google-oauth',
    name: 'Google OAuth',
    i18nKey: 'common:text.googleOAuth',
  },
  {
    route: '/trial-data',
    name: 'Trial Data',
    i18nKey: 'dataVisualization:pageTitle',
  },
  {
    route: '/bhb-trial-data',
    name: 'Trial Data',
    i18nKey: 'dataVisualization:pageTitle',
  },
  {
    route: '/bhb-trial-data/lower-bhb',
    name: 'See recommendations',
    i18nKey: 'dataVisualization:bhb.seeRecommendations',
  },
  {
    route: '/bhb-trial-data/raise-bhb',
    name: 'See recommendations',
    i18nKey: 'dataVisualization:bhb.seeRecommendations',
  },
];

export const privateRoutesWithoutHeader = [
  '/community-agreement',
  '/community-unavailable',
  '/main-goal',
  '/download',
];

export const Private = () => {
  useRemoveTrailingSlash();
  const { pathname, search } = useLocation();
  const { openDialogV2, closeDialog } = useDialog();
  const user = useReadLocalStorage('me', false);
  const { init: initActivityDetector } = useActivityDetector();
  const { saveToLocalStorage } = useLocalStorage('me', false);
  const { saveToLocalStorage: saveRedirectUrl } = useLocalStorage(
    'redirectUrl',
    false
  );
  const authStatus = useAuthStatus();
  const redirectUrl = useReadLocalStorage('redirectUrl', false);
  const navigate = useNavigate();

  // Load initial data necessary for app to be ready
  const { isFeatureEnabled, isLoading: loadingFeatureFlags } = useFeatureFlag();
  const showExplore = isFeatureEnabled('show_explore');
  const showIntercom = isFeatureEnabled('show_intercom');
  const showLearningContent = isFeatureEnabled('show_learning_content');
  const { value: isOtpEnabled, isLoading: loadingIsOtpEnabled } =
    useSystemSetting<boolean>('isOtpEnabled', { allowAutomaticRefetch: false });

  const { data: profileData, networkStatus } = useGetProfileQuery({
    notifyOnNetworkStatusChange: true,
  });
  const { data: intercomHashData, networkStatus: intercomNetworkStatus } =
    useGetUserIntercomHashKeyQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        platform: PlatformType.Web,
      },
      skip: !showIntercom,
    });
  const loadingProfile = networkStatus === NetworkStatus.loading;
  const { isLoading: loadingNotifications } = useNotifications();
  const { analyticsClient, isLoading: loadingAnalytics } = useAnalytics();
  const { isLoading: loadingChat } = useChat();
  const { data: communityData, networkStatus: communityNetworkStatus } =
    useGetCommunityUserQuery({
      notifyOnNetworkStatusChange: true,
    });
  const isBanned = communityData?.getCommunityUser?.isBanned;
  const loadingCommunity = communityNetworkStatus === NetworkStatus.loading;
  const loadingIntercomKey = intercomNetworkStatus === NetworkStatus.loading;

  const patientName = profileData?.me.name || '';
  const intercomHashKey = intercomHashData?.userIntercomHashKey;

  const isLoading =
    loadingAnalytics ||
    loadingChat ||
    loadingFeatureFlags ||
    loadingNotifications ||
    loadingProfile ||
    loadingCommunity ||
    loadingIntercomKey ||
    loadingIsOtpEnabled;

  if (showIntercom && intercomHashKey) {
    Intercom('boot', {
      email: profileData?.me.email,
      user_id: profileData?.me.id,
      name: profileData?.me.name,
      user_hash: intercomHashKey,
      language_override: profileData?.me.language || 'en-US',
    });
  }

  useEffect(() => {
    if (user) {
      initActivityDetector({
        lastLoginTime: user.lastLogin,
        rememberMe: user.rememberMe,
      });
    }
  }, [user, initActivityDetector]);

  useEffect(() => {
    const verifyAuthField = 'isAuthenticated';
    const acceptedSmsField = 'acceptedSms';
    const eventPayload: SignInEvent = {};

    if (profileData?.me && analyticsClient && user && verifyAuthField in user) {
      eventPayload.rememberMe = !!user.rememberMe;

      if (acceptedSmsField in user) {
        eventPayload.defaultNotificationPreference = user.acceptedSms
          ? NotificationTypeV2.Sms
          : NotificationTypeV2.Push;
      } else {
        eventPayload.defaultNotificationPreference =
          profileData.me.default_notification_preference || undefined;
      }

      analyticsClient.track('Sign In', eventPayload);

      const cachedUser = omit(user, [acceptedSmsField, verifyAuthField]);
      saveToLocalStorage(cachedUser, true, profileData?.me.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.me.id, analyticsClient, saveToLocalStorage]);

  useEffect(() => {
    const showDialog = async () => {
      const isFirstTime = !user?.language;
      const isDifferent = !profileData?.me.language?.includes(i18n.language);

      if (profileData?.me.language && isDifferent && !isLoading) {
        await i18n.changeLanguage(profileData.me.language);
        await saveToLocalStorage({ language: profileData.me.language });

        if (isFirstTime) {
          openDialogV2({
            title: 'Welcome to Faeth!',
            content:
              'Your preferred language is set to English. You can change this setting by contacting your Faeth coach.',
            showCloseButton: false,
            showBackButton: false,
            i18nKeyTitle: 'home:welcomeDialog.title',
            i18nKeyContent: 'home:welcomeDialog.content',
            confirmButtonLabel: 'Dismiss',
            handleConfirm: async () => closeDialog(),
          });
        }
      }
    };

    showDialog();
  }, [
    profileData?.me.language,
    isLoading,
    user,
    saveToLocalStorage,
    closeDialog,
    openDialogV2,
  ]);

  useEffect(() => {
    // Remove this if when useRemoveTrailingSlash is removed
    if (!pathname.match('/.*/$')) {
      analyticsClient?.page();
    }
  }, [analyticsClient, pathname]);

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_OUT) {
      const currentUrl = pathname + search;
      if (currentUrl !== '/' && currentUrl !== redirectUrl?.url) {
        saveRedirectUrl({ url: currentUrl });
      }
    } else if (authStatus === AuthStatus.SIGNED_IN && redirectUrl?.url) {
      navigate(redirectUrl.url);
      saveRedirectUrl({}, true);
    }
  }, [authStatus, navigate, redirectUrl, saveRedirectUrl, pathname, search]);

  if (isLoading) {
    return <SplashLoader />;
  }

  const renderCourseNestedRoutes = () => (
    <Route path="courses">
      <Route index element={<CoursesWrapper />} />
      {showLearningContent ? (
        <>
          <Route path=":moduleId" element={<CourseHome />} />
          <Route path=":moduleId/:itemId" element={<CourseItem />} />
        </>
      ) : (
        <>
          <Route
            path=":moduleId"
            element={<Navigate replace to="/courses" />}
          />
          <Route
            path=":moduleId/:itemId"
            element={<Navigate replace to="/courses" />}
          />
        </>
      )}
    </Route>
  );

  const RedirectToPath: React.FC<{ from: string; to: string }> = ({
    from,
    to,
  }) => {
    const { pathname, search } = useLocation();
    const subPath = pathname.replace(from, '');
    return <Navigate replace to={`${to}${subPath}${search}`} />;
  };

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <AppBar userName={patientName} />
      <Routes>
        <Route element={<ScrollToTop />}>
          {showExplore ? (
            <>
              <Route
                path="/courses/*"
                element={
                  <RedirectToPath from="/courses" to="/learning/courses" />
                }
              />
              <Route path="learning">
                <Route index element={<Learning />} />
                <Route path="articles" element={<Articles />} />
                {renderCourseNestedRoutes()}
                <Route path="recipes" element={<Recipes />} />
                <Route path="recipe" element={<Recipe />} />
              </Route>
            </>
          ) : (
            <>
              <Route
                path="/learning/courses/*"
                element={
                  <RedirectToPath from="/learning/courses" to="/courses" />
                }
              />
              {renderCourseNestedRoutes()}
            </>
          )}
          <Route path="profile">
            <Route index element={<Profile />} />
            <Route path="your-account" element={<YourAccount />} />
            <Route
              path="medical-information"
              element={<MedicalInformation />}
            />
            <Route path="notifications" element={<Notifications />} />
            <Route path="help">
              <Route index element={<Help />} />
              <Route path=":id" element={<FaqCategory />} />
            </Route>
            {!isOtpEnabled && (
              <Route
                path="change-password"
                element={
                  <ProfileChangePassword email={profileData?.me.email} />
                }
              />
            )}
            <Route path="documents">
              <Route index element={<Documents />} />
              <Route path="coaches-uploads" element={<CoachesUploads />} />
              <Route path="your-uploads" element={<YourUploads />} />
            </Route>
          </Route>
          <Route path="meals">
            <Route index element={<Meals />} />
            <Route path="help" element={<MealsHelp />} />
            <Route path=":id" element={<Delivery />} />
          </Route>
          <Route path="surveys">
            <Route index element={<Surveys />} />
            <Route path=":id" element={<SurveySteps />} />
          </Route>
          <Route path="community">
            <Route index element={<CommunityInitial />} />
            {isBanned ? (
              <>
                <Route path="*" element={<Navigate to="/community" />} />
              </>
            ) : (
              <>
                <Route
                  path="notifications"
                  element={<CommunityNotifications />}
                />
                <Route path=":id" element={<ViewPost />} />
                <Route path="write-post" element={<AddOrEditPost />} />
                <Route path=":id/edit-post" element={<AddOrEditPost />} />
              </>
            )}
          </Route>
          <Route path="community-agreement" element={<Community />} />
          <Route
            path="community-unavailable"
            element={<CommunityUnavailable />}
          />
          {!showIntercom && <Route path="chat" element={<Chat />} />}
          {!isOtpEnabled && (
            <Route path="reset-password" element={<ResetPassword />} />
          )}
          <Route path="trial-data" element={<TrialData />} />
          <Route path="bhb-trial-data">
            <Route index element={<BHBTrialData />} />
            <Route
              path="lower-bhb"
              element={<BHBRecommendations type="lower" />}
            />
            <Route
              path="raise-bhb"
              element={<BHBRecommendations type="raise" />}
            />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<DefaultBasePage type="notFound" />} />
          <Route path="/download" element={<Download />} />
        </Route>
      </Routes>
    </Box>
  );
};
