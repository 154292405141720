import React from 'react';
import { DefaultBasePage } from '@components';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { Link, useSnackbar } from '@fdha/web-ui-library';
import { signOut } from '@aws-amplify/auth';
import { useFeatureFlag, useSystemSetting } from '@fdha/common-hooks';

import ProfileCard from './ProfileCard';

const Profile = () => {
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();

  const { isFeatureEnabled } = useFeatureFlag();
  const showDocuments = isFeatureEnabled('show_documents');

  const { value: isOtpEnabled } = useSystemSetting<boolean>('isOtpEnabled', {
    allowAutomaticRefetch: false,
  });

  const { value: termsOfServiceLink } = useSystemSetting<string>(
    'termsOfServiceLink',
    {
      allowAutomaticRefetch: false,
    }
  );

  const { value: privacyPolicyLink } = useSystemSetting<string>(
    'privacyPolicyLink',
    {
      allowAutomaticRefetch: false,
    }
  );

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      showSnackbarV2({
        message: 'Error to log out',
        severity: 'error',
        i18nKey: 'profile:snackbar.errorLogOut',
      });
    }
  };

  return (
    <DefaultBasePage
      i18nKeyTitle="profile:title"
      title="Profile"
      contentSize="small"
    >
      <>
        <Stack spacing={2}>
          <ProfileCard
            id="yourAccount"
            i18nKey="profile:yourAccount.title"
            text="Your Account"
            handleClick={() => navigate('/profile/your-account')}
          />
          {showDocuments && (
            <ProfileCard
              id="documents"
              i18nKey="profile:documents.title"
              text="Documents"
              handleClick={() => navigate('/profile/documents')}
            />
          )}
          <ProfileCard
            id="medicalInformation"
            i18nKey="profile:medicalInformation.title"
            text="Medical Information"
            handleClick={() => navigate('/profile/medical-information')}
          />
          <ProfileCard
            id="notifications"
            i18nKey="profile:notifications.title"
            text="Notifications"
            leftIcon="bell-outline"
            handleClick={() => navigate('/profile/notifications')}
          />
          <ProfileCard
            id="help"
            i18nKey="profile:help.title"
            text="Help"
            leftIcon="question-mark-circle-outline"
            handleClick={() => navigate('/profile/help')}
          />
          {!isOtpEnabled && (
            <ProfileCard
              id="changePassword"
              i18nKey="profile:changePassword.title"
              text="Change Password"
              handleClick={() => navigate('/profile/change-password')}
            />
          )}
          <ProfileCard
            id="logout"
            i18nKey="profile:logout"
            text="Log Out"
            rightIcon="log-out-outline"
            handleClick={handleLogout}
          />
        </Stack>
        <Box display="flex" flexDirection="column" mt={3} gap={1}>
          <Link
            href={termsOfServiceLink}
            target="_blank"
            i18nKey="profile:termsOfUse"
          >
            Access Terms of Use
          </Link>
          <Link
            href={privacyPolicyLink}
            target="_blank"
            i18nKey="profile:privacyPolicy"
          >
            Access Privacy Policy
          </Link>
        </Box>
      </>
    </DefaultBasePage>
  );
};

export default Profile;
