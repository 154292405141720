import { SnackbarContent, Typography } from '@fdha/web-ui-library';

import { baseUrl, doGet } from './commonApi';
import { getPatientDeepLink } from './getPatientDeepLink';

export const handleInviteFriends = async (
  showSnackbar: (snackbar: SnackbarContent) => void
) => {
  try {
    const url = `${baseUrl}/environment` || '';
    const environment = await doGet(url);

    const link = getPatientDeepLink(environment);

    navigator.clipboard.writeText(link);
    showSnackbar({
      message: (
        <>
          <Typography
            sx={{ fontWeight: 'bold', margin: 0 }}
            i18nKey="common:snackbar.linkClipBoard"
          >
            Link copied to clipboard
          </Typography>
          <Typography i18nKey="common:snackbar.linkInvite">
            You can share the link to invite friends.
          </Typography>
        </>
      ),
      severity: 'success',
    });
  } catch (error) {
    console.error('Error to get url');
  }
};
