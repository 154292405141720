import React from 'react';
import { Typography } from '@fdha/web-ui-library';
import { Paper, useTheme } from '@mui/material';

export const SurveysEmptyState = () => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        backgroundColor: theme.palette.success.light,
      }}
    >
      <Typography
        i18nKey="surveys:screen.emptyState"
        color={theme.palette.primary.contrastText}
        fontWeight="bold"
        textAlign="center"
      >
        Your surveys are up to date, Great job!
      </Typography>
    </Paper>
  );
};
