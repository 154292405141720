import React, { useCallback, useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  DialogProvider,
  Loader,
  LoadingBarProvider,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { Private, Public } from '@routes';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { isMobile } from '@utils';
import { NotificationsProvider } from '@hooks';
import {
  ActivityDetectorProvider,
  AnalyticsProvider,
  ChatProvider,
  FeatureFlagsProvider,
  Hub,
  StreamUser,
  WebSocketProvider,
} from '@fdha/common-hooks';
import {
  useGetApiKeysLazyQuery,
  useGetCommunityUserQuery,
  useGetProfileLazyQuery,
  useUpdateUserTimezoneMutation,
} from '@fdha/graphql-api-patient';
import { onError } from '@apollo/client/link/error';
import { useNavigate } from 'react-router';

const hubs: Hub[] = [
  { basePath: '/', name: 'Home' },
  { basePath: '/activities', name: 'Activities' },
  { basePath: '/chat', name: 'Chat' },
  { basePath: '/community', name: 'Community' },
  { basePath: '/community-agreement', name: 'Community' },
  { basePath: '/community-unavailable', name: 'Community' },
  { basePath: '/courses', name: 'Learning' },
  { basePath: '/learning', name: 'Learning' },
  { basePath: '/main-goal', name: 'Surveys' },
  { basePath: '/meals', name: 'Meals' },
  { basePath: '/profile', name: 'Profile Settings' },
  { basePath: '/surveys', name: 'Surveys' },
];

function App() {
  const authStatus = useAuthStatus();
  const client = useApolloClient();
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [updateUserTimezone] = useUpdateUserTimezoneMutation();
  const { refetch: refetchCommunity } = useGetCommunityUserQuery();

  const isMobileBrowser = isMobile();
  const unsupported = isMobileSize && isMobileBrowser;

  const onSignOut = useCallback(async () => {
    showSnackbarV2({
      severity: 'warning',
      message: 'Session expired. Please log in again.',
      closeOnClickOutside: true,
      closeAutomatically: false,
      i18nKey: 'common:snackbar.sessionExpired',
    });
    closeDialog();
    navigate('/', { replace: true });
  }, [showSnackbarV2, closeDialog, navigate]);

  const updatePatientTimezone = async (
    patientId: string,
    timezone?: string | null
  ) => {
    const patientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (patientId && patientTimezone !== timezone) {
      await updateUserTimezone({
        variables: {
          timezone: patientTimezone,
        },
      });
    }
  };

  const errorControl = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (!extensions) {
          return;
        }
        if (extensions['code'] === 'COMMUNITY_BANNED') {
          refetchCommunity();
        }
      });
    }
  });

  client.setLink(errorControl.concat(client.link));

  const [getApiKeys, { data: apiKeysData }] = useGetApiKeysLazyQuery();
  const [getProfile, { data: profileData }] = useGetProfileLazyQuery({
    onCompleted: (result) =>
      updatePatientTimezone(result.me.id, result.me.timezone),
  });

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_IN) {
      getApiKeys();
      getProfile();
    }
  }, [authStatus, getApiKeys, getProfile]);

  useEffect(() => {
    Intercom('shutdown');

    const clearCache = async () => {
      try {
        await client.cache.reset();
      } catch (e) {
        console.log('Error cleaning cache', e);
      }
    };

    if (authStatus === AuthStatus.SIGNED_OUT) {
      clearCache();
    }
  }, [authStatus, client]);

  useEffect(() => {
    if (unsupported) {
      navigate('/download', { replace: true });
    }
  }, [unsupported, navigate]);

  const { me } = profileData || {};

  const streamKey = apiKeysData?.apiKeys.stream;
  const streamUserData: StreamUser | undefined = useMemo(() => {
    return me
      ? {
          name: me.name,
          picture: me.picture,
          streamUserId: me.stream_user_id,
          streamUserToken: me.stream_user_token,
        }
      : undefined;
  }, [me]);

  if (authStatus === AuthStatus.LOADING) {
    return <Loader />;
  }

  return (
    <>
      <Box>
        {authStatus === AuthStatus.SIGNED_IN ? (
          <FeatureFlagsProvider>
            <WebSocketProvider>
              <AnalyticsProvider hubs={hubs}>
                <DialogProvider>
                  <ChatProvider streamKey={streamKey} user={streamUserData}>
                    <NotificationsProvider>
                      <ActivityDetectorProvider onSignOut={onSignOut}>
                        <LoadingBarProvider>
                          <Private />
                        </LoadingBarProvider>
                      </ActivityDetectorProvider>
                    </NotificationsProvider>
                  </ChatProvider>
                </DialogProvider>
              </AnalyticsProvider>
            </WebSocketProvider>
          </FeatureFlagsProvider>
        ) : (
          <Public />
        )}
      </Box>
    </>
  );
}

export default App;
