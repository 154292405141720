import { DefaultBasePage } from '@components';
import { useSystemSetting } from '@fdha/common-hooks';
import { Button, Typography } from '@fdha/web-ui-library';
import {
  AppBar,
  Box,
  Link,
  List,
  ListItem,
  Stack,
  styled,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { FC } from 'react';

const installItemsiOS = [
  {
    text: '1. On the App Store page for Faeth App, tap or click the <strong>Get</strong> button. If you see the Open button instead of Get button, you have already downloaded that app',
    i18nKey: 'download:install.ios.steps.step1',
  },
  {
    text: '2. Double-click the side button on the right side of your iPhone - or the top button on your iPad',
    i18nKey: 'download:install.ios.steps.step2',
  },
  {
    text: "3. If you use Face ID or Touch ID to unlock your device, you'll glance at your device or touch the Home button to authenticate the download. You might need to enter your Apple ID password to complete the download",
    i18nKey: 'download:install.ios.steps.step3',
  },
  {
    text: '4. The app will begin downloading automatically. Once the download is complete, the button will change to <strong>Open</strong>',
    i18nKey: 'download:install.ios.steps.step4',
  },
  {
    text: '5. Tap or click <strong>Open</strong> to launch the app',
    i18nKey: 'download:install.ios.steps.step5',
  },
];

const installItemsAndroid = [
  {
    text: '1. On the Play Store page for Faeth App, tap or click the <strong>Install</strong> button. If you see the Open button instead of Install button, you have already downloaded that app',
    i18nKey: 'download:install.android.steps.step1',
  },
  {
    text: '2. The app will begin downloading and then installing automatically. Once the installation is complete, the button will change to <strong>Open</strong>',
    i18nKey: 'download:install.android.steps.step2',
  },
  {
    text: '3. Tap or click <strong>Open</strong> to launch the app',
    i18nKey: 'download:install.android.steps.step3',
  },
];

const loginItems = [
  {
    text: '1. On the Faeth App, tap or click the <strong>Get started</strong> button',
    i18nKey: 'download:login.steps.step1',
  },
  {
    text: '2. Enter the phone number or email associated with your account, then tap or click the <strong>Continue</strong> button',
    i18nKey: 'download:login.steps.step2',
  },
  {
    text: '3. On the next screen, enter the 6-digit code received by SMS or Email to access the app',
    i18nKey: 'download:login.steps.step3',
  },
  {
    text: '4. Tap or click the <strong>Continue</strong> button to access Faeth App main screen',
    i18nKey: 'download:login.steps.step4',
  },
];

interface SectionTitleProps {
  title: string;
  i18nKey: string;
}

interface SectionListProps {
  items: { text: string; i18nKey: string }[];
  text?: SectionTitleProps;
  image?: { src: string; alt: string };
  showIcon?: boolean;
  sx?: SxProps<Theme>;
}

export const Image = styled('img')({
  maxWidth: '100%',
  width: 'fit-content',
});

const Download = () => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const currentYear = new Date().getFullYear();

  const { value: termsOfServiceLink } = useSystemSetting<string>(
    'termsOfServiceLink',
    {
      allowAutomaticRefetch: false,
    }
  );

  const { value: privacyPolicyLink } = useSystemSetting<string>(
    'privacyPolicyLink',
    {
      allowAutomaticRefetch: false,
    }
  );

  const getLink = (link: string) => (
    <Link href={link} target="_blank" color={theme.palette.text.secondary} />
  );

  const termsLink = getLink(termsOfServiceLink || '');
  const privacyLink = getLink(privacyPolicyLink || '');

  const SectionTitle: FC<SectionTitleProps> = ({ title, i18nKey }) => {
    return (
      <Typography
        variant="h4"
        color={theme.palette.primary.main}
        i18nKey={i18nKey}
        sx={{ mb: 4 }}
      >
        {title}
      </Typography>
    );
  };

  const SectionList: FC<SectionListProps> = ({
    items,
    text,
    image,
    showIcon,
    sx,
  }) => {
    return (
      <Stack sx={sx} spacing={4}>
        {text && (
          <Stack direction="row" alignItems="center" spacing={4}>
            {showIcon && (
              <Box sx={{ maxWidth: theme.spacing(3) }}>
                <img
                  src="/images/faeth-icon.png"
                  alt="Faeth Icon"
                  width="100%"
                />
              </Box>
            )}
            <Typography variant="h5" i18nKey={text.i18nKey}>
              {text.title}
            </Typography>
          </Stack>
        )}
        <List sx={{ padding: 0 }} disablePadding>
          {items.map((item, index) => (
            <ListItem key={index}>
              <Typography i18nKey={item.i18nKey}>{item.text}</Typography>
            </ListItem>
          ))}
        </List>
        {image && <Image src={image.src} alt={image.alt} />}
      </Stack>
    );
  };

  return (
    <>
      <DefaultBasePage
        showNavigation={false}
        showHorizontalScroll={false}
        sx={{ backgroundColor: theme.palette.background.paper }}
      >
        <AppBar
          sx={{
            height: theme.spacing(8),
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            borderBottom: `1px solid ${grey[300]}`,
          }}
        >
          <Box
            sx={{
              height: '100%',
              alignContent: 'center',
              ml: 4,
            }}
          >
            <img src="/images/Faeth_Logo_Small.png" alt="Faeth logo" />
          </Box>
        </AppBar>
        <Typography variant="h3" i18nKey="download:title" sx={{ marginY: 10 }}>
          Download app guide
        </Typography>
        <Stack spacing={10}>
          <Box>
            <SectionTitle
              title="Download the app"
              i18nKey="download:download.title"
            />
            <Typography
              variant="body1"
              i18nKey="download:download.buttonDescription"
              sx={{ mb: 2 }}
            >
              Select the button below to download directly from your device's
              app store
            </Typography>
            <Button
              variant="contained"
              i18nKey="download:download.button"
              href="https://faeth.page.link/home"
              target="_blank"
              sx={{ mb: 2 }}
            >
              Download App
            </Button>
            <Typography
              variant="body1"
              i18nKey="download:download.qrDescription"
            >
              You can also download it on your cellphone using the QR-code if
              you are viewing this from your computer
            </Typography>
            <Box
              sx={{
                display: 'flex',
                maxWidth: '120px',
                mt: 2,
                padding: 1,
                border: `1px solid ${theme.palette.action.main}`,
                borderRadius: '8px',
              }}
            >
              <img src="/images/qrcode.png" alt="QR Code" width="100%" />
            </Box>
          </Box>
          <Box>
            <SectionTitle
              title="Install on your device"
              i18nKey="download:install.title"
            />
            <SectionList
              showIcon
              text={{
                title: 'Install on iOS (App Store)',
                i18nKey: 'download:install.ios.title',
              }}
              items={installItemsiOS}
              image={{
                src: '/images/install-ios.png',
                alt: 'Install app iOS',
              }}
              sx={{ mb: 4 }}
            />
            <SectionList
              showIcon
              text={{
                title: 'Install on Android (Play Store)',
                i18nKey: 'download:install.android.title',
              }}
              items={installItemsAndroid}
              image={{
                src: '/images/install-android.png',
                alt: 'Install app Android',
              }}
            />
          </Box>
          <Box>
            <SectionTitle
              title="Login to Faeth App"
              i18nKey="download:login.title"
            />
            <SectionList
              items={loginItems}
              image={{
                src: '/images/login.png',
                alt: 'Login',
              }}
            />
          </Box>
          <Box>
            <SectionTitle title="Need help?" i18nKey="download:help.title" />
            <Typography i18nKey="download:help.description" sx={{ mb: 2 }}>
              If you experience any issues or need assistance during the
              download or installation process reach out to our support team
            </Typography>
            <Button
              variant="outlined"
              i18nKey="download:help.button"
              href="mailto:support@faeththerapeutics.com?subject=[Support Request] Faeth App Download, Install and Login"
              target="_blank"
            >
              Contact Support
            </Button>
          </Box>
        </Stack>
      </DefaultBasePage>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.action.main}`,
          padding: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobileSize ? 'column' : 'row',
            justifyContent: isMobileSize ? 'center' : 'space-between',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Box>
            <img src="/images/Faeth_Logo_Small.png" alt="Faeth logo" />
          </Box>
          <Stack spacing={1}>
            <Typography variant="body2" i18nKey={undefined}>
              © {currentYear} Faeth Therapeutics Inc.
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography
                variant="body2"
                i18nKey="download:footer.privacyPolicy"
                i18nComponents={{
                  privacyLink,
                }}
              >
                {`<privacyLink>Privacy Policy</privacyLink>`}
              </Typography>
              <Typography
                variant="body2"
                i18nKey={undefined}
                color={theme.palette.text.secondary}
              >
                |
              </Typography>
              <Typography
                variant="body2"
                i18nKey="download:footer.terms"
                i18nComponents={{
                  termsLink,
                }}
              >
                {`<termsLink>Terms of Service</termsLink>`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default Download;
