import React, { FC } from 'react';
import { ImageBasePage } from '@components';
import { ChangePasswordV2 } from '@fdha/web-auth';
import {
  NotificationTypeV2,
  useUpdateProfileMutation,
} from '@fdha/graphql-api-patient';
import { useLocalStorage } from '@hooks';

interface ChangePasswordProps {
  termsOfServiceLink: string;
  privacyPolicyLink: string;
}

const ChangePassword: FC<ChangePasswordProps> = ({
  termsOfServiceLink,
  privacyPolicyLink,
}) => {
  const [updateProfile] = useUpdateProfileMutation();
  const { saveToLocalStorage } = useLocalStorage('me', false);

  const handleSmsToggle = async (acceptedSms: boolean) => {
    const defaultNotificationPreference = acceptedSms
      ? NotificationTypeV2.Sms
      : NotificationTypeV2.Push;

    await updateProfile({
      variables: {
        props: {
          default_notification_preference: defaultNotificationPreference,
        },
      },
    });
  };
  const handleRememberMe = (rememberMe: boolean, userId: string) => {
    saveToLocalStorage(
      {
        rememberMe,
        isAuthenticated: true,
        lastLogin: new Date(),
      },
      false,
      `pat_${userId}`
    );
  };

  return (
    <ImageBasePage
      image={{
        url: '/images/woman_02.png',
        altText: 'Old Woman',
      }}
      title="Set password"
      i18nKeyTitle="login:changePassword.setPassword"
      titleVariant="h4"
      alignHeader="flex-start"
    >
      <ChangePasswordV2
        showTosToggle
        showSmsToggle
        termsOfServiceLink={termsOfServiceLink}
        privacyPolicyLink={privacyPolicyLink}
        handleSmsToggle={handleSmsToggle}
        handleRememberMe={handleRememberMe}
      />
    </ImageBasePage>
  );
};

export default ChangePassword;
