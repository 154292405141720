import { DefaultBasePage } from '@components';
import { Loader, Typography, useSnackbar } from '@fdha/web-ui-library';
import { useAnalytics, useFeatureFlag } from '@fdha/common-hooks';
import {
  GetPreferencesQuery,
  NotificationTypeV2,
  useGetPreferencesQuery,
  useGetProfileQuery,
  useUpdatePreferencesMutation,
} from '@fdha/graphql-api-patient';
import { Stack, capitalize, useTheme } from '@mui/material';
import React, { useState } from 'react';

import NotificationCard, { NotificationValue } from './NotificationCard';

type NotificationPreferencesPatientFromQuery =
  GetPreferencesQuery['preferencesV2']['notification'];

type NotificationName = keyof Omit<
  NotificationPreferencesPatientFromQuery,
  '__typename'
>;

const Notifications = () => {
  const [notificationPreferences, setNotificationPreferences] =
    useState<NotificationPreferencesPatientFromQuery>();

  const { loading: loadingPreferences } = useGetPreferencesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (fetchedData) => {
      setNotificationPreferences(fetchedData.preferencesV2.notification);
    },
  });
  const { data: userData } = useGetProfileQuery();

  const { isFeatureEnabled } = useFeatureFlag();
  const showFoodOps = isFeatureEnabled('show_food_ops');
  const showCommunity = isFeatureEnabled('show_community');
  const showCommunityWebPatient = isFeatureEnabled(
    'show_community_web_patient'
  );
  const { analyticsClient } = useAnalytics();

  const isCommunityEnabled = showCommunity && showCommunityWebPatient;

  const [updatePreferences] = useUpdatePreferencesMutation();

  const { showSnackbarV2 } = useSnackbar();
  const theme = useTheme();

  const showErrorSnackbar = () => {
    showSnackbarV2({
      message: 'Sorry, something went wrong. Please try again.',
      severity: 'error',
      i18nKey: 'common:snackbar.somethingWentWrong',
    });
  };

  const getTrackNotificationName = (name: string) =>
    `${capitalize(name)} Notifications`;

  const handleChange = async (
    notificationName: NotificationName,
    newValue: NotificationValue,
    oldValue: NotificationValue
  ) => {
    if (!notificationPreferences) {
      console.log('Empty notification preferences');
      showErrorSnackbar();

      return;
    }

    const newState = { ...notificationPreferences };
    newState[notificationName] = {
      enabled: newValue.enabled,
      type: newValue.type as NotificationTypeV2,
    };

    setNotificationPreferences(newState);

    try {
      await updatePreferences({
        variables: {
          prefs: { notification: { [notificationName]: newValue } },
        },
      });

      if (newValue.enabled !== oldValue.enabled) {
        analyticsClient?.track('Notification Permissions Changed', {
          name: getTrackNotificationName(notificationName),
          enabled: newValue.enabled,
        });
      } else {
        analyticsClient?.track('Notification Type Changed', {
          name: getTrackNotificationName(notificationName),
          type: newValue.type,
        });
      }
    } catch (error) {
      newState[notificationName] = {
        enabled: oldValue.enabled,
        type: oldValue.type as NotificationTypeV2,
      };

      setNotificationPreferences(newState);

      console.log(error);
      showErrorSnackbar();
    }
  };

  const showMeals = userData?.me.is_ct && showFoodOps;

  return (
    <DefaultBasePage
      title="Notifications"
      contentSize="small"
      i18nKeyTitle="profile:notifications.title"
    >
      {loadingPreferences ? (
        <Loader />
      ) : (
        <>
          <Stack spacing={1} mb={2}>
            <Typography
              i18nKey="profile:notifications.push"
              variant="body2"
              color={theme.palette.text.secondary}
            >
              Push: Notifications will be sent only through the mobile version
              of the app, if you have it downloaded on your phone.
            </Typography>
            <Typography
              i18nKey="profile:notifications.sms"
              variant="body2"
              color={theme.palette.text.secondary}
            >
              SMS: Notifications will be sent only through text. Messaging and
              data rates may apply.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <NotificationCard
              name="Chat"
              description="Allow notifications for new Chat messages."
              value={{
                enabled: !!notificationPreferences?.chat.enabled,
                type: notificationPreferences?.chat.type.valueOf(),
              }}
              onChange={(newValue, oldValue) =>
                handleChange('chat', newValue, oldValue)
              }
            />
            {isCommunityEnabled && (
              <NotificationCard
                name="Community"
                description="Allow notifications for interactions in the Community."
                value={{
                  enabled: !!notificationPreferences?.community.enabled,
                  type: notificationPreferences?.community.type.valueOf(),
                }}
                onChange={(newValue, oldValue) =>
                  handleChange('community', newValue, oldValue)
                }
              />
            )}
            <NotificationCard
              name="Documents"
              description="Allow notifications for new documents and changes to documents."
              value={{
                enabled: !!notificationPreferences?.documents.enabled,
                type: notificationPreferences?.documents.type.valueOf(),
              }}
              onChange={(newValue, oldValue) =>
                handleChange('documents', newValue, oldValue)
              }
            />
            <NotificationCard
              name="Learning"
              description="Allow reminders for new Learning content assigned to you by your Coach."
              value={{
                enabled: !!notificationPreferences?.learning.enabled,
                type: notificationPreferences?.learning.type.valueOf(),
              }}
              onChange={(newValue, oldValue) =>
                handleChange('learning', newValue, oldValue)
              }
            />
            {showMeals && (
              <NotificationCard
                name="Meals"
                description="Allow notifications and reminders for Meals deliveries and orders."
                value={{
                  enabled: !!notificationPreferences?.meals.enabled,
                  type: notificationPreferences?.meals.type.valueOf(),
                }}
                onChange={(newValue, oldValue) =>
                  handleChange('meals', newValue, oldValue)
                }
              />
            )}
            <NotificationCard
              name="Surveys"
              description="Allow reminders for new, incomplete and late surveys assigned to you."
              value={{
                enabled: !!notificationPreferences?.survey.enabled,
                type: notificationPreferences?.survey.type.valueOf(),
              }}
              onChange={(newValue, oldValue) =>
                handleChange('survey', newValue, oldValue)
              }
            />
          </Stack>
        </>
      )}
    </DefaultBasePage>
  );
};

export default Notifications;
